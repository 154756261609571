const RecommendedCoursesMap = {
    "EnglishTesting": {
        "Courses": [
            "Beginner",
            "Intermediate",
            "PIM",
            "IntermediateB1",
            "UpperIntermediateB2"
        ],
        "Startpoints": [
            "0",
            "4",
            "6",
            "9",
            "12"
        ],
        "Duration": 60
    },
    "DeutschTesting": {
        "Courses": [
            "Beginner",
            "Intermediate",
            "PIM"
        ],
        "Startpoints": [
            "0",
            "6",
            "12"
        ],
        "Duration": 45
    },
    "FrenchTesting": {
        "Courses": [
            "Beginner",
            "Intermediate",
            "PIM"
        ],
        "Startpoints": [
            "0",
            "6",
            "12"
        ],
        "Duration": 45
    },
    "ItalianTesting": {
        "Courses": [
            "Beginner",
            "Intermediate",
            "PIM"
        ],
        "Startpoints": [
            "0",
            "6",
            "12"
        ],
        "Duration": 45
    },
    "PortugueseTesting": {
        "Courses": [
            "Beginner",
            "Intermediate",
            "PIM"
        ],
        "Startpoints": [
            "0",
            "6",
            "12"
        ],
        "Duration": 45
    },
    "SpanishTesting": {
        "Courses": [
            "Beginner",
            "Intermediate",
            "PIM"
        ],
        "Startpoints": [
            "0",
            "6",
            "12"
        ],
        "Duration": 45
    },
    "DeutschGruWoEinstufungstest": {
        "Courses": [
            "GruWoStarter",
            "GruwoMittel",
            "GruwoFortgeschritten"
        ],
        "Startpoints": [
            "0",
            "6",
            "12"
        ],
        "Duration": 45
    },
    "DeutschHotellerieTest": {
        "Courses": [
            "HotellerieDemocontent"
        ],
        "Startpoints": [
            "0"
        ],
        "Duration": 45
    },
    "EnglishModelingTesting": {
        "Courses": [
            "ModelContentGesamtInklEvents"
        ],
        "Startpoints": [
            "0"
        ],
        "Duration": 30
    },
    "EnglishGruWoEN_Einstufungstest": {
        "Courses": [
            "GruWo1Beginner",
            "GruWo2Intermediate",
            "GruWo3Advanced"
        ],
        "Startpoints": [
            "0",
            "8",
            "15"
        ],
        "Duration": 45
    }
};

export default RecommendedCoursesMap;
