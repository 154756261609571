const LanguageMap = {
    ARABIC: 'ar',
    BULGARIAN: 'bg',
    BRAZILIAN: 'br',
    CZECH: 'cz',
    CATALAN: 'ca',
    GERMAN: 'de',
    GREEK: 'el',
    ENGLISH: 'en',
    LATIN_AMERICAN_SPANISH: 'er',
    SPANISH: 'es',
    MEXICAN: 'ex',
    FARSI: 'fa',
    FRENCH: 'fr',
    HINDI: 'hi',
    CROATIAN: 'hr',
    HUNGARIAN: 'hu',
    ARMENIAN: 'hy',
    ITALIAN: 'it',
    JAPANESE: 'ja',
    KOREAN: 'ko',
    LITHUANIAN: 'lt',
    DUTCH: 'nl',
    NORWEGIAN: 'no',
    POLISH: 'pl',
    PORTUGUESE: 'pt',
    ROMANIAN: 'ro',
    RUSSIAN: 'ru',
    KINYARWANDA: 'rw',
    SLOVAK: 'sk',
    SLOVENE: 'sl',
    SERBIAN: 'sr',
    SWAHILI: 'sw',
    TURKISH: 'tr',
    URDU: 'ur',
    UKRAINIAN: 'uk',
    SIMPLIFIED_CHINESE: 'zh',
    TRADITIONAL_CHINESE: 'zt'
};

export default LanguageMap;
