
import Variant from './config/oup';
const Constants = {
    ...{
        AES_SALT: "tenKsZVM4EcSSPQKxKt7BnZRPkyaUcb5",
        CODE_TYPE: {
            COMPANY_CODE: 'COMPANY_CODE',
            COURSE_CODE: 'COURSE_CODE',
            USER_MIGRATION: 'USER_MIGRATION'
        },
        LOWER_CASE_REGEX: new RegExp("(?=.*[a-z])"),
        UPPER_CASE_REGEX: new RegExp("(?=.*[A-Z])"),
        NUMERIC_REGEX: new RegExp("(?=.*[0-9])"),
        SPECIAL_REGEX: new RegExp("(?=.*[!@#$%^&*])")
}   ,
    ...Variant
};




export default Constants;
