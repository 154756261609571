import firebase from '../firebase';

class FirestoreConnector {

    firestore = firebase.firestore();

    getUserData = async (userId: string) => {
        try {
            const userDataSnapshot = await this.firestore.collection('oxfordUserData').doc(userId).get();
            return userDataSnapshot.data();
        } catch (e) {
            console.log("Couldn't get user data from Firestore, the following error occurred: ", e);
        }
    }

    setUserMigrated = async (userId: string) => {
        try {
            return await this.firestore.collection('oxfordUserData').doc(userId).update({userMigrated: true});
        } catch (e) {
            console.log("Couldn't set userMigrated to true in Firestore, the following error occurred: ", e);
        }
    }
}

export default FirestoreConnector;
