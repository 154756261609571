import FirestoreConnector from './FirestoreConnector';

class FirestoreDatabaseService {
    firestoreConnector = new FirestoreConnector();

    getUserData = async (userId: string) => {
        return await this.firestoreConnector.getUserData(userId);
    };

    setUserMigrated = async (userId: string) => {
        return await this.firestoreConnector.setUserMigrated(userId);
    }

}

export default FirestoreDatabaseService;
