import React from 'react';
import PromiseComponent from "../PromiseComponent";
import i18next from "i18next";
import {Sentry} from "react-activity";
import FirebaseDatabaseService from "../services/FirebaseDatabaseService";
import Constants from '../Constants';
import OUPCourseNameMap from '../maps/OUPCourseNameMap';


class CodeRedemption extends PromiseComponent {

    firebaseDatabaseService = new FirebaseDatabaseService();

    props: {
        goOnWithValidCode: () => mixed,
        codeString: string
    };

    state: {
        voucher: string,
        voucherValid: boolean,
        voucherLoading: boolean,
        voucherResponse: string,
        language: string,
        title: string,
        sponsorId: number,
        retry: boolean
    } = {
        voucher: '',
        voucherValid: false,
        voucherLoading: false,
        voucherResponse: '',
        language: '',
        title: '',
        sponsorId: -1,
        retry: false
    };

    componentDidMount = async () =>  {
        if (this.props.codeString) {
            await this.changeVoucherText();
        }
    };

    changeVoucherText = async (event) => {
        let text = this.props.codeString ? this.props.codeString : event.target.value;
        text = text.replace(/\s/g, "").toUpperCase();
        await this.promisedSetState({
            voucher: text,
            voucherValid: false,
        });
        if (this.state.voucher.length === 8 || (this.state.voucher.length === 6 && this.props.codeString)) {
            await this.promisedSetState({
                voucherLoading: true,
                voucherResponse: "",
            });
            await this.checkVoucher()
        } else if (this.state.voucher.length > 8) {
            await this.promisedSetState({
                voucherLoading: false,
                voucherResponse: "",
            });
        }
    };

    checkVoucher = async () => {
        if (this.state.voucher.length === 6) {
            const communities = await this.firebaseDatabaseService.communities();
            let codeInformation = { exists: false, valid: false, expired: false };
            for (let id in communities) {
                if (communities.hasOwnProperty(id) && communities[id].ExpirationDate && communities[id].ExpirationDate > Date.now() && communities[id].Active) {
                    // sponsor is not expired
                    codeInformation = await this.firebaseDatabaseService.isCompanyCodeValidAndUnused(+id - 100000, this.state.voucher);
                    // check if valid sponsor is found
                    if (codeInformation.exists) {
                        await this.promisedSetState({sponsorId: id});
                        break;
                    }
                }
            }
            if (codeInformation.valid && !codeInformation.expired) {
                await this.promisedSetState({
                    voucherLoading: false,
                    voucherValid: true,
                    voucherResponse: i18next.t('MESSAGE__VALID_VOUCHER_CODE_FOR_X_COMMUNITY_ENTERED', {name: communities[this.state.sponsorId].Name.default}),
                });
                await this.props.goOnWithValidCode(this.state.voucher, '', '', this.state.sponsorId, Constants.CODE_TYPE.COMPANY_CODE);
            } else {
                let errorString = "MESSAGE__INVALID_VOUCHER_CODE_ENTERED";
                if(codeInformation.exists) {
                    errorString = "MESSAGE__USED_VOUCHER_CODE_ENTERED";
                }
                if(codeInformation.expired) {
                    errorString = "MESSAGE__EXPIRED_VOUCHER_CODE_ENTERED";
                }
                this.promisedSetState({
                    voucherLoading: false,
                    voucherResponse: i18next.t(errorString),
                    retry: true
                });
            }
        } else if (this.state.voucher.length === 8) {
            try {
                let codeInformation: {
                    exists: boolean,
                    valid: boolean,
                    expired: boolean,
                    language: string,
                    title: string,
                    communityId: string,
                    validForMonths: ?number
                } = await this.firebaseDatabaseService.isCourseCodeValidAndUnused(this.state.voucher);

                if (codeInformation.valid && !codeInformation.expired) {
                    const sponsorId = codeInformation.communityId;
                    const courseTitle = OUPCourseNameMap[codeInformation.title] || codeInformation.title;
                    await this.promisedSetState({
                        voucherLoading: false,
                        voucherValid: true,
                        voucherResponse: i18next.t('MESSAGE__VALID_VOUCHER_CODE_ENTERED'),
                        language: codeInformation.language,
                        title: courseTitle,
                        sponsorId: sponsorId
                    });
                    await this.props.goOnWithValidCode(this.state.voucher, codeInformation.language, courseTitle, sponsorId, Constants.CODE_TYPE.COURSE_CODE, codeInformation.validForMonths);
                } else {
                    let errorString = "MESSAGE__INVALID_VOUCHER_CODE_ENTERED";
                    if (codeInformation.exists) {
                        errorString = "MESSAGE__USED_VOUCHER_CODE_ENTERED";
                    }
                    if (codeInformation.expired) {
                        errorString = "MESSAGE__EXPIRED_VOUCHER_CODE_ENTERED";
                    }
                    this.promisedSetState({
                        voucherLoading: false,
                        voucherResponse: i18next.t(errorString),
                        retry: true
                    });
                }
            } catch (e) {
                alert(
                    i18next.t('ALERT__ERROR_TITLE')
                );
            }
        } else {
            this.promisedSetState({
                voucherLoading: false,
                voucherResponse: i18next.t('MESSAGE__INVALID_VOUCHER_CODE_ENTERED'),
            });
        }
    };


    renderVoucherInput = () => {
        return (
            <input
                maxLength="8"
                onChange={this.changeVoucherText}
                value={this.state.voucher}
                autoCorrect="false"
                autoCapitalize="characters"
                placeholder={i18next.t('FORM_PLACEHOLDER_BUSINESS_REGISTRATION__VOUCHER_CODE')}
            />
        )
    };

    renderVoucherResponse = () => {
        return (
            <div className="info-text">
                <p>
                    {
                        this.state.voucherResponse ?
                            this.state.voucherResponse :
                            i18next.t("DESCRIPTION_START__WELCOME_TITLE", {appname: Constants.APP_NAME}) + ' ' + i18next.t("DESCRIPTION_COMMUNITY_ACCESS__PLEASE_ENTER_ACCESS_KEY")
                    }
                </p>
            </div>
        );
    };

    renderVoucherButtonText = () => {
        if (this.state.voucherLoading) {
            return (
                <Sentry size={12}/>
            );
        } else if (!this.state.retry) {
            return (
                <p>{i18next.t('GLOBAL_BUTTON__CONTINUE')}</p>
            );
        } else {
            return (
                <p>{i18next.t('ALERT_BUTTON__RETRY')}</p>
            )
        }
    };

    render() {
        const onButtonClick = this.state.retry ? () => {
            this.promisedSetState({retry: false, voucher: "", voucherResponse: i18next.t('DESCRIPTION_COMMUNITY_ACCESS__PLEASE_ENTER_ACCESS_KEY')})
        } : () => {
            this.promisedSetState({ voucherLoading: true, voucherResponse: "" });
            this.checkVoucher();
        };
        return (
            <div className="content-container">
                {
                    this.renderVoucherResponse()
                }
                {
                    this.renderVoucherInput()
                }
                <button
                    disabled={!(this.state.voucher.length > 5)}
                    onClick={onButtonClick}>
                    {
                        this.renderVoucherButtonText()
                    }
                </button>
            </div>
        )
    }
}

export default CodeRedemption;
