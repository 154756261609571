import PromiseComponent from '../PromiseComponent';
import React from 'react';
import i18next from 'i18next';

class MigrationOnboarding extends PromiseComponent {
    props: {
        continue: () => mixed
    }
    makeStringPartsBold = (uiString: string) => {
      const stringParts = uiString.split('@bold@');
      const text = [];
        for (let i = 0; i < stringParts.length; i++) {
            if (i % 2 === 0) {
                text.push(stringParts[i]);
            } else {
                text.push(<b>{stringParts[i]}</b>);
            }
        }
        return text;
    };

    render () {
        const title = this.makeStringPartsBold(i18next.t('OUP_CODE_PORTAL_WELCOME_SCREEN_HEADING'));
        return (
            <div className="content-container">
                <div className={'info-text-onboarding'}>
                    <p>{title}</p>
                    <ul>
                        <li>{i18next.t('OUP_CODE_PORTAL_BULLETPOINT_1')}</li>
                        <li>{i18next.t('OUP_CODE_PORTAL_BULLETPOINT_2')}</li>
                        <li>{i18next.t('OUP_CODE_PORTAL_BULLETPOINT_3')}</li>
                    </ul>
                    <p>{i18next.t('OUP_CODE_PORTAL_SET_UP_ACCOUNT')}</p>
                </div>
                <button
                    onClick={this.props.continue}>
                    <p>{i18next.t('GLOBAL_BUTTON__CONTINUE')}</p>
                </button>
            </div>
        )
    }
}

export default MigrationOnboarding;
