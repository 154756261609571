import React from 'react';
import PromiseComponent from "../PromiseComponent";
import i18next from "i18next";
import FirebaseDatabaseService from "../services/FirebaseDatabaseService";
import TranslationsHelper from "../helpers/TranslationsHelper";

class ShowCourse extends PromiseComponent {

    firebaseDatabaseService = new FirebaseDatabaseService();

    props: {
        language: string,
        title: string,
        codeString: string,
        courses: Object,
        goOnWithCourse: () => mixed
    };

    state: {
        course: Object,
        imgSource: string,
        translatedCourseName: string
    } = {
        course: {},
        imgSource: '',
        translatedCourseName: ''
    };

    componentDidMount = async () => {
        let course = this.getCourseInformation();
        await this.loadImageAndSaveCourseInfo(course);
    };

    getCourseInformation =  () => {

        let courseTitle = "";
        for (const languageTitle in this.props.courses) {
            if (this.props.courses[languageTitle].language === this.props.language && this.props.courses[languageTitle].title === this.props.title) {
                courseTitle = languageTitle;
                break;
            }
        }
        return this.props.courses[courseTitle];

    };

    loadImageAndSaveCourseInfo = async (course) => {
        let path = course.image;
        let imgSource = await this.firebaseDatabaseService.getDownloadUrl(path);
        const translatedCourseName = await TranslationsHelper.translateCourseDisplayName(this.props.language, this.props.title);
        await this.promisedSetState({imgSource: imgSource, course: course, translatedCourseName: translatedCourseName});
    };

    renderUnlockInfo = () => {
        return (
            <div className="content-container">
                <p>{i18next.t('MESSAGE__VALID_VOUCHER_CODE_ENTERED_COURSE_PAGE', {code: this.props.codeString})}</p>
            </div>
        )
    };

    renderCourseInfo = () => {
        return (
            <div className="content-container">
                <div className="">
                    <img src={this.state.imgSource} className="download-link-image" alt="Company Logo"/>
                </div>
                <div className={'info-text'}>
                    <p><b>{ this.state.translatedCourseName }</b></p>
                    <p>{this.state.course.size}</p>
                    <p>{this.state.course.items} {i18next.t('TOPICS_OVERVIEW__ITEMS')}</p>
                </div>
            </div>
        )
    };

    render () {

        return (
            <div>
                {
                    this.renderUnlockInfo()
                }
                {
                    this.renderCourseInfo()
                }
                <button onClick={() => this.props.goOnWithCourse(this.state.course)}>
                    <p>{i18next.t('GLOBAL_BUTTON__CONTINUE')}</p>
                </button>
            </div>
        )

    }

}

export default ShowCourse;
