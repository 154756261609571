
const oup = {
    CONFIG: {
        apiKey: "AIzaSyBr01q59mXGYcJvFjN5i48w-7u4eALQocU",
        authDomain: "learnmatch-oup.firebaseapp.com",
        databaseURL: "https://learnmatch-oup.firebaseio.com",
        projectId: "learnmatch-oup",
        storageBucket: "learnmatch-oup.appspot.com"
    },
    APP_TITLE: "OEVT2 registration",
    APP_NAME: "Oxford English Vocab Trainer 2",
    COMPANY_NAME: "Oxford University Press",
    LOGO: require('../assets/img/oevt2-logo.png'),
    BRANCH_KEY: 'key_live_agUZ3aLBAo2ROOkIhAsAUlcbrtetuNIo',
    FALLBACKLINK: "https://oevt2.app.link",
    BRANCH_CHANNEL: "OEVT2 CodeRedemption WebClient",
    BRANCH_FEATURE: "CodeRedemption",
    BRANCH_CAMPAIGN: "CodeRedemption",
    LOCAL_AGB_PATH: '/terms-and-conditions',
    CHECK_SPONSOR_COURSE_CODE_REDEMPTION: false,

    // links to agbs
    AGBLinks: {
        ar: '/terms/terms-and-conditions',
        bg: '/terms/terms-and-conditions',
        br: '/terms/terms-and-conditions',
        ca: '/terms/terms-and-conditions',
        cz: '/terms/terms-and-conditions',
        de: '/terms/terms-and-conditions',
        el: '/terms/terms-and-conditions',
        en: '/terms/terms-and-conditions',
        er: '/terms/terms-and-conditions',
        es: '/terms/terms-and-conditions',
        ex: '/terms/terms-and-conditions',
        fa: '/terms/terms-and-conditions',
        fr: '/terms/terms-and-conditions',
        hi: '/terms/terms-and-conditions',
        hr: '/terms/terms-and-conditions',
        hu: '/terms/terms-and-conditions',
        hy: '/terms/terms-and-conditions',
        it: '/terms/terms-and-conditions',
        ja: '/terms/terms-and-conditions',
        jj: '/terms/terms-and-conditions',
        ko: '/terms/terms-and-conditions',
        lt: '/terms/terms-and-conditions',
        nl: '/terms/terms-and-conditions',
        no: '/terms/terms-and-conditions',
        pl: '/terms/terms-and-conditions',
        pt: '/terms/terms-and-conditions',
        ro: '/terms/terms-and-conditions',
        ru: '/terms/terms-and-conditions',
        rw: '/terms/terms-and-conditions',
        sk: '/terms/terms-and-conditions',
        sl: '/terms/terms-and-conditions',
        sq: '/terms/terms-and-conditions',
        sr: '/terms/terms-and-conditions',
        sw: '/terms/terms-and-conditions',
        tr: '/terms/terms-and-conditions',
        uk: '/terms/terms-and-conditions',
        ur: '/terms/terms-and-conditions',
        zh: '/terms/terms-and-conditions',
        zt: '/terms/terms-and-conditions'
    },

    // links to privacy policies
    PrivacyLinks: {
        ar: 'https://global.oup.com/privacy',
        bg: 'https://global.oup.com/privacy',
        br: 'https://global.oup.com/privacy',
        ca: 'https://global.oup.com/privacy',
        cz: 'https://global.oup.com/privacy',
        de: 'https://global.oup.com/privacy',
        el: 'https://global.oup.com/privacy',
        en: 'https://global.oup.com/privacy',
        er: 'https://global.oup.com/privacy',
        es: 'https://global.oup.com/privacy',
        ex: 'https://global.oup.com/privacy',
        fa: 'https://global.oup.com/privacy',
        fr: 'https://global.oup.com/privacy',
        hi: 'https://global.oup.com/privacy',
        hr: 'https://global.oup.com/privacy',
        hu: 'https://global.oup.com/privacy',
        hy: 'https://global.oup.com/privacy',
        it: 'https://global.oup.com/privacy',
        ja: 'https://global.oup.com/privacy',
        jj: 'https://global.oup.com/privacy',
        ko: 'https://global.oup.com/privacy',
        lt: 'https://global.oup.com/privacy',
        nl: 'https://global.oup.com/privacy',
        no: 'https://global.oup.com/privacy',
        pl: 'https://global.oup.com/privacy',
        pt: 'https://global.oup.com/privacy',
        ro: 'https://global.oup.com/privacy',
        ru: 'https://global.oup.com/privacy',
        rw: 'https://global.oup.com/privacy',
        sk: 'https://global.oup.com/privacy',
        sl: 'https://global.oup.com/privacy',
        sq: 'https://global.oup.com/privacy',
        sr: 'https://global.oup.com/privacy',
        sw: 'https://global.oup.com/privacy',
        tr: 'https://global.oup.com/privacy',
        uk: 'https://global.oup.com/privacy',
        ur: 'https://global.oup.com/privacy',
        zh: 'https://global.oup.com/privacy',
        zt: 'https://global.oup.com/privacy'
    }
};

export default oup;
