import i18next from "i18next";
import FirebaseDatabaseService from '../services/FirebaseDatabaseService';

class TranslationsHelper {
    static async translateCourseDisplayName (courseLanguageKey: string, courseTitleKey: string): string {
        let courseTitle = '';
        const locale = i18next.language.substr(0, 2);
        const courseDisplayNameKey = courseLanguageKey + courseTitleKey;
        const firebaseDatabaseService = new FirebaseDatabaseService();
        const courseTranslations = await firebaseDatabaseService.getCourseTranslation(courseDisplayNameKey);
        courseTitle = courseTranslations[locale] || courseTranslations['default'];
        return courseTitle;
    }
}

export default TranslationsHelper;
