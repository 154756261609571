const OUPCourseNameMap = {
    // mapping oup course name to learnmatch course name
    academic_word_list: "OUPAcademicWordlist",
    business_keywords: "OUPBusinessKeywords",
    business_result_advanced: "OUPBusinessResultAdvanced",
    business_result_elementary: "OUPBusinessResultElementary",
    business_result_intermediate: "OUPBusinessResultIntermediate",
    business_result_pre_intermediate: "OUPBusinessResultPreIntermediate",
    business_result_starter: "OUPBusinessResultBeginner",
    business_result_upper_intermediate: "OUPBusinessResultUpperIntermediate",
    consumer_coursebook: "OUPEssentialVocabulary",
    english_file_beginner: "OUPEnglishFileBeginner",
    english_file_elementary: "OUPEnglishFileElementary",
    english_file_intermediate: "OUPEnglishFileIntermediate",
    english_file_intermediate_plus: "OUPEnglishFileIntermediatePlus",
    english_file_pre_intermediate: "OUPEnglishFilePre-Intermediate",
    english_file_upper_intermediate: "OUPEnglishFileUpperIntermediate",
    english_plus_1: "OUPEnglishPlus1",
    english_plus_level_2: "OUPEnglishPlus2",
    english_plus_3: "OUPEnglishPlus3",
    english_plus_level_4: "OUPEnglishPlus4",
    headway_5e_advanced: "OUPHeadway5eAdvanced",
    headway_5e_beginner: "OUPHeadway5eBeginner",
    headway_5e_elementary: "OUPHeadway5eElementary",
    headway_5e_intermediate: "OUPHeadway5eIntermediate",
    headway_5e_pre_intermediate: "OUPHeadway5ePreIntermediate",
    headway_5e_upper_intermediate: "OUPHeadway5eUpperIntermediate",
    headway_beginner: "OUPHeadway4eBeginner",
    headway_elementary: "OUPHeadway4eElementary",
    headway_intermediate: "OUPHeadway4eIntermediate",
    headway_plus_beginner: "OUPHeadwayPlusBeginner",
    headway_plus_elementary: "OUPHeadwayPlusElementary",
    headway_plus_intermediate: "OUPHeadwayPlusIntermediate",
    headway_plus_pre_intermediate: "OUPHeadwayPlusPreIntermediate",
    headway_pre_intermediate: "OUPHeadway4ePreIntermediate",
    international_express_intermediate: "OUPInternationalExpressIntermediate",
    international_express_pre_intermediate: "OUPInternationalExpressPreIntermediate",
    international_express_upper_intermediate: "OUPInternationalExpressUpperIntermediate",
    milestones_in_english_a1: "OUPMilestonesInEnglishA1",
    milestones_in_english_a2: "OUPMilestonesInEnglishA2",
    milestones_in_english_b1: "OUPMilestonesInEnglishB1",
    milestones_in_english_b1_plus: "OUPMilestonesInEnglishB1Plus",
    milestones_in_english_b2: "OUPMilestonesInEnglishB2",
    navigate_a1_beginner: "OUPNavigateA1Beginner",
    navigate_a2_elementary: "OUPNavigateA2Elementary",
    navigate_b1_plus_intermediate: "OUPNavigateB1PlusIntermediate",
    navigate_b1_pre_intermediate: "OUPNavigateB1PreIntermediateV2",
    navigate_b2_upper_intermediate: "OUPNavigateB2UpperIntermediate",
    navigate_c1_advanced: "OUPNavigateC1Advanced",
    q_skills_for_success_level_0: "OUPQSkills0",
    q_skills_for_success_level_1: "OUPQSkillsLevel1",
    q_skills_for_success_level_2: "OUPQSkillsLevel2",
    q_skills_for_success_level_3: "OUPQSkillsLevel3",
    solutions_gold_intermediate: "OUPSolutionsGoldIntermediate",
    solutions_gold_pre_intermediate: "OUPSolutionsGoldPreIntermediate",
    solutions_gold_upper_intermediate: "OUPSolutionsGoldUpperIntermediate",
    step_forward_introductory_level: "OUPStepForwardIntro",
    step_forward_level_1: "OUPStepForwardLevel1",
    step_forward_level_2: "OUPStepForwardLevel2",
    step_forward_level_3: "OUPStepForwardLevel3",
    step_forward_level_4: "OUPStepForwardLevel4",
    topics_advanced: "OUPTopicsAdvanced",
    topics_beginner: "OUPTopicsBeginner",
    vision_level_1: "OUPVision1",
    vision_level_2_a2_b1: "OUPVision2",
    vision_level_3: "OUPVision3",
    vocabulary_practice_beginner: "OUPVocabularyPracticeBeginner",
    vocabulary_practice_elementary: "OUPVocabularyPracticeElementary",
    vocabulary_practice_intermediate: "OUPVocabularyPracticeIntermediate",
    vocabulary_practice_intermediate_plus: "OUPVocabularyPracticeIntermediatePlus",
    vocabulary_practice_pre_intermediate_demo: "OUPVocabularyPracticePreIntermediate",
    vocabulary_practice_upper_intermediate: "OUPVocabularyPracticeUpperIntermediate",
    vocabulary_practice_teens_1: "OUPVocabularyPracticeTeens1",
    vocabulary_practice_teens_2_demo: "OUPVocabularyPracticeTeens2",
    vocabulary_practice_teens_3: "OUPVocabularyPracticeTeens3",
    vocabulary_practice_teens_4: "OUPVocabularyPracticeTeens4",
    voyage_a1: "OUPVoyageA1",
    voyage_a2: "OUPVoyageA2",
    voyage_b1: "OUPVoyageB1",
    voyage_b1_plus: "OUPVoyageB1Plus",
    voyage_b2: "OUPVoyageB2",
    voyage_c1: "OUPVoyageC1"
};

export default OUPCourseNameMap;
