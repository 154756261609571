const TestingContentMap = {
    "EnglishTesting": require("../assets/content/EnglishTesting/EnglishTesting.json"),
    "DeutschTesting": require("../assets/content/DeutschTesting/DeutschTesting.json"),
    "FrenchTesting": require("../assets/content/FrenchTesting/FrenchTesting.json"),
    "ItalianTesting": require("../assets/content/ItalianTesting/ItalianTesting.json"),
    "PortugueseTesting": require("../assets/content/PortugueseTesting/PortugueseTesting.json"),
    "SpanishTesting": require("../assets/content/SpanishTesting/SpanishTesting.json"),
    "DeutschGruWoEinstufungstest": require("../assets/content/DeutschGruWoEinstufungstest/DeutschGruWoEinstufungstest.json"),
    "DeutschHotellerieTest": require("../assets/content/DeutschHotellerieTest/HotellerieTest.json"),
    "EnglishModelingTesting": require("../assets/content/EnglishModelingTesting/EnglishModelingTesting.json"),
    "EnglishGruWoEN_Einstufungstest": require("../assets/content/EnglishGruWoEN_Einstufungstest/EnglishGruwoEinstufungstest.json")
};

export default TestingContentMap;
