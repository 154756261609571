import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
    <div class="container" >
        <h1>Terms &amp; Conditions</h1>
        <p>Updated on: 5 May 2016 <br/> Effective from: 5 May 2016</p>
        <p>
            These Terms (defined below) govern your use of OEVT (defined below). They override any other terms and conditions found on the OEVT website or any other product terms which you may have already accepted in relation to your use of other products provided
            by Oxford University Press (OUP).
        </p>
        <p>
            In consideration of OUP making OEVT available to you, you agree to accept these Terms. Where you are accessing OEVT through an app, these Terms are subject to the applicable app store’s terms and conditions.
        </p>
        <p>
            You should print a copy of these Terms or save them to your computer for future reference.
        </p>
        <section id="interpretation">
            <h3>1. Interpretation</h3>
            <p>
                1.1 In these Terms, unless the context otherwise requires, the following expressions shall have the following meanings:
            </p>
            <p>
                <b>Authorised User</b> means (i) you, the Buyer who has a valid username and password to access OEVT and has valid authorisation to access any Product(s); or (ii) you an individual who has (a) a valid username and password to access OEVT; and (b) been authorised
                by a Buyer who has a valid licence to access any Product(s) and "the Authorised User" means the relevant Authorised User.
            </p>
            <p>
                <b>Buyer means</b> (i) you the individual who has purchased a licence to use a Product or any number of Products; or (ii) the Organisation who has purchased a licence to use a Product or any number of Products.
            </p>
            <p>
                <b>Fees</b> mean the relevant Product fees payable by the Buyer to access the Product in question on OEVT.
            </p>
            <p>
                <b>Intellectual Property Rights</b> mean all patents, rights to inventions, copyright and related rights, trade marks, service marks, trade, business and
                domain names, rights in trade dress or get-up, rights in goodwill or to sue for passing off, unfair competition rights, rights in designs, database right, topography rights, moral rights, rights in confidential information (including knowhow and trade secrets) and any other intellectual property rights, in each case whether registered or unregistered and including all applications for and renewals or extensions of such rights, and all similar or equivalent rights or forms of protection in any part of the world.
            </p>
            <p>
                <b>Licence Period</b> means the period set out in the Product Specific Term during which the Product in question is accessible by You.
            </p>
            <p>
                <b>OEVT</b> means OUP's Oxford English Vocabulary Trainerapplication.
            </p>
            <p>
                <b>Organisation</b> means an institution or an entity that is granted access to OEVT by OUP, and who has purchased a licence to access a Product or any number of Products from OUP. For the avoidance of doubt, where an Organisation consists of a number of individual entities, branches and/or a federation, each entity, branch or entity within that federation shall be deemed as an individual Organisation unless otherwise agreed in writing by OUP.
            </p>
            <p>
                <b>OUP, we, us, or our</b> means or mean (as the context requires) Oxford University Press, a department of the University of Oxford whose address is Great Clarendon Street, Oxford, OX2 6DP, United Kingdom.
            </p>
            <p>
                <b>Parent</b> means a parent or guardian of an Authorised User who is a student.
            </p>
            <p>
                <b>Personal Data</b> means information about an individual person that can identify that individual person (e.g. name, email address or age).
            </p>
            <p>
                <b>Product(s)</b> mean the OUP digital learning materials purchased by a Buyer for the use by its Authorised User(s) which are accessed and used via OEVT.
            </p>
            <p>
                <b>Product Specific Terms</b> mean the licence and usage terms and conditions relating a specific Product which unless specified in the order form
                for the relevant Product, would mean that the licence and usage of the Product would be on a perpetual basis.
            </p>
            <p>
                <b>Software</b> means the software provided by us or our licensors which enables You to use and access OEVT and the Products via OEVT.
            </p>
            <p>
                <b>Terms</b> mean these terms and conditions.
            </p>
            <p>
                <b>You or Your</b> means the Organisation, the Buyer or the Authorised Users (as the context requires).
            </p>
            <p>
                1.2 Headings in these conditions shall not affect their interpretation.
            </p>
            <p>
                1.3 Words importing any gender shall include any other gender.
            </p>
            <p>
                1.4 Words importing the singular number shall include the plural number and vice versa including the definitions referred to in Clause 1.1 hereof.
            </p>
            <p>
                1.5 Where any word or expression is defined in these Terms, the definition shall extend to all grammatical variations and cognate expressions of the word or expression so defined.
            </p>
            <p>
                1.6 All references to provisions of statutes include such provisions as amended, modified or reenacted.
            </p>
            <p>
                1.7 Where applicable, the Buyer shall procure that each and every one of its Authorised Users comply with the provisions of these Terms; and the Buyer shall be liable for any breach of these Terms by its Authorised Users as though the breach was committed by the Buyer. Without prejudice to the generality of the foregoing:
            </p>
            <div class="pl-1">
                <p>
                    1.7.1 an Organisation is responsible for ensuring that its users (including Parents) comply with these Terms; and
                </p>
                <p>
                    1.7.2 the Buyer who has purchased a licence to access a Product is responsible for ensuring that the actual user of OEVT and/or the Product complies with these Terms.
                </p>
            </div>
        </section>

        <section id="license">
            <h3>2. License</h3>
            <p>
                2.1 In consideration of the Fees paid by the Buyer to OUP, OUP grants to You, a non-exclusive licence to access and use the Product(s) via OEVT during the Licence Period. In this regard, You are entitled to use the Software (in object code form) to access the Products and to use OEVT for educational or business purposes on the basis of a single licence for a single user.
            </p>
            <p>
                2.2 Notwithstanding Clause 2.1, You must not:
            </p>
            <div class="pl-1">
                <p>
                    2.2.1 sub-license or assign the benefit or burden of the licence set out in Clause 2.1 in whole or in part to anyone;
                </p>
                <p>
                    2.2.2 attempt to duplicate, modify, disclose or distribute any portion of the Software;
                </p>
                <p>
                    2.2.3 attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form any of the Software, except as is allowed by any applicable law which is incapable of exclusion by agreement between OUP and You;
                </p>
                <p>
                    2.2.4 transfer, temporarily or permanently, any rights or obligations under these Terms (save as provided under these Terms); or
                </p>
                <p>
                    2.2.5 attempt to obtain, or assist others in obtaining access to OEVT, the Software, and the Products other than as provided under Clause 2.
                </p>
            </div>
        </section>

        <section id="accessing-oevt-and-your-products">
            <h3>3. Accessing OEVT and your products</h3>
            <p>
                3.1 You acknowledge that You will only be able to access the Products on OEVT during relevant Licence Periods for the Products in question and that Your access and use of each of the Products will be governed by the Product Specific Terms which relate to that Product. Without prejudice to the foregoing, You must not:
            </p>
            <div class="pl-1">
                <p>
                    3.1.1 systematically make printed or electronic copies of content and/or materials in any Products for any purpose in either print or electronic format;
                </p>
                <p>
                    3.1.2 remove or alter the copyright notices or other means of identification or disclaimers as they appear in any of the Products;
                </p>
                <p>
                    3.1.3 display or distribute any part of the Products on any electronic network, including without limitation the Internet and the World Wide Web, where access is possible by anyone not an Authorised User; and/or
                </p>
                <p>
                    3.1.4 permit anyone other than the Authorised Users to access or use any of the Products including any content and material making up those Products.
                </p>
            </div>
            <p>
                3.2 If You are purchasing any Products on behalf of Your Organisation, You warrant that You have the authority to do so and agree to the Product Specific Terms on behalf of Your Organisation.
            </p>
            <p>
                3.3 To access and use the Products, You will need to create a username and password and log into Your OEVT account using Your username and password. You should thereafter find Your Products listed your “Library”
            </p>
        </section>

        <section id="your-obligations">
            <h3>4. Your obligations</h3>
            <p>
                4.1 The Buyer is responsible for the selection of the Products it purchases and whether such Products meet its and its Authorised Users requirements.
            </p>
            <p>
                4.2 You agree not to make available Your username and/or password to anyone save if you contact OUP’s support team and if requested by OUP’s support team. If there has been a disclosure of Your username and/or password, You agree to notify us promptly of such disclosure, so that we can take appropriate security measures.
            </p>
            <p>
                4.3 You undertake not to send and/or transmit to other Authorised Users, any content which is illegal, obscene, threatening, defamatory, discriminatory, promote illegal or unlawful activity, or be otherwise actionable or in violation of any rules, Intellectual Property Rights, regulations or laws to which such content is subject.
            </p>
            <p>
                4.4 If we suspect that a breach of Clauses 4.3, 4.6,
                4.8 or 7.2 has occurred or is likely to occur, we may, without giving notice and liability to You suspend Your access to OEVT and/or Your Products.
            </p>
            <p>
                4.5 You agree that You will promptly notify us of any errors or inaccuracies which relate to the Products. In this regards, please contact us at eltsupport@oup.com.
            </p>
            <p>
                4.6 You must not introduce any malicious codes or harmful element e.g. virus and Trojans onto OEVT
            </p>
            <p>
                4.7 Unless otherwise agreed in writing by OUP, You are solely responsible for configuring Your computer devices in order to access OEVT and the Products, and to provide for Your own virus protection software. To access OEVT and/or the Products, Your computer device must meet the specification detailed the related product documentation.
            </p>
            <p>
                4.8 You warrant that any Personal Data which You provide to OUP for OUP to process would not put OUP in breach of any applicable data protection laws and You agree to indemnify and keep OUP indemnified against breach by You of the foregoing warranty.
            </p>
            <p>
                4.9 The Organisation agrees to indemnify and keep us indemnified for any losses, damages, fines arising from or which are connected to any breach by it and/or its Authorised Users of Clauses 4.3, 4.6, 4.8 and 7.2.
            </p>
        </section>

        <section id="our-obligations">
            <h3>5. Our obligations</h3>
            <p>
                5.1 We will:
            </p>
            <div class="pl-1">
                <p>
                    5.1.1 use reasonable endeavours to ensure that Your Products are accessible on OEVT; and
                </p>
                <p>
                    5.1.2 (if we have agreed to do so in writing) provide You with training and help notes on how to use OEVT.
                </p>
            </div>
        </section>

        <section id="withdrawal-of-product">
            <h3>6. Withdrawal of products and changes to software functionality</h3>
            <p>
                6.1 We reserve the right at any time to withdraw a Product including any components within a Product (e.g. an activity from a course) for any reason including:
            </p>
            <div class="pl-1">
                <p>
                    6.1.1 if we no longer retain the right to publish such material; or
                </p>
                <p>
                    6.1.2 if in our sole discretion, we believe that such material may infringe the Intellectual Property Rights of third parties or is defamatory, obscene, unlawful or otherwise objectionable.
                </p>
            </div>

            <p>
                In the circumstances above, we shall notify the Buyer of such withdrawal as soon as reasonably practicable.
            </p>
            <p>
                6.2 On receipt of the notice referred to in Clause 6.1, the Buyer (if the Buyer is not itself an Authorised User) shall promptly inform its Authorised Users of OUP’s notice set out in Clause 6.1 and You agree to immediately cease all use of the withdrawn material and shall comply with our instructions with respect to the deletion and/or removal of such withdrawn material.
            </p>
            <p>
                6.3 We reserve the right to add or remove functionality of the Software without giving any reasons and in these circumstances, we will notify you of such change as soon as reasonably practicable.
            </p>
        </section>

        <section id="intellectual-property-rights">
            <h3>7. Intellectual property rights</h3>
            <p>
                7.1 As between You and OUP, You acknowledge that OUP and/or OUP’s licensors own all Intellectual Property Rights in OEVT, the Software and the Products. Except as expressly stated in these Terms and the Product Specific Terms, OUP does not grant You any rights to or in any Intellectual Property Rights or any other rights or licences.
            </p>
            <p>
                7.2 If You become aware of any Intellectual Property Rights infringement or potential Intellectual Property Rights Infringement involving OEVT, the Software and/or the Products (e.g. an unauthorised third party is using OEVT or if You get a letter from a third party stating that the Software infringes such third party's Intellectual Property Rights) You must inform us promptly.
                We will:
            </p>
            <div class="pl-1">
                <p>
                    7.2.1 in our absolute discretion, determine what action if any shall be taken in respect of the matter;
                </p>
                <p>
                    7.2.2 have sole control over and shall conduct any action as we deem necessary; and
                </p>
                <p>
                    7.2.3 pay all costs in connection with that action and we shall be entitled to all damages and other sums which may be paid or awarded as a result of any such action. You agree to (at our cost) provide us with any assistance which we may request.
                </p>
            </div>
            <p>
                7.3 In the defence or settlement of a claim that OEVT, the Software and/or the Products infringe third party Intellectual Property Rights ('Infringing Items'), we may at our discretion:
            </p>
            <div class="pl-1">
                <p>
                    7.3.1 obtain for You the right to continue using the Infringing Items;
                </p>
                <p>
                    7.3.2 replace or modify the Infringing Items with equivalent items so that they become noninfringing; or
                </p>
                <p>
                    7.3.3 if such remedies are not reasonably available, withdraw the Infringing Items and we will refund any of the Fees paid by the Buyer (less a reasonable sum in respect of the Buyer’s and its Authorised Users' use of the Infringing Items).
                </p>
            </div>
            <p>
                7.4 Clauses 7.2 and 7.3 set out Your sole and exclusive rights and remedies, and our entire obligations and liability, for infringement by us of Intellectual Property Rights.
            </p>
        </section>

        <section id="warranties">
            <h3>8. Warranties</h3>
            <p>
                8.1 Subject to the other provisions in these Terms and to our right to carry out scheduled maintenance, during the period when You have access to OEVT, we warrant to the Buyer that we will use reasonable endeavours to ensure that OEVT is available to the Buyer’s Authorised Users.
            </p>
            <p>
                8.2 We do not warrant that the use of OEVT and/or the Products will be uninterrupted or error-free. We may also carry out scheduled maintenance from time to time.
            </p>
            <p>
                8.4 Except as expressly stated in these Terms, there are no conditions, warranties, representations or other terms, express or implied, that are binding on us. Any condition, warranty, representation or other term concerning the supply of OEVT, the Products and the Software which might otherwise be implied into, or incorporated in, these Terms whether by statute, common law or otherwise, is excluded to the fullest extent permitted by law.
            </p>
        </section>

        <section id="liability">
            <h3>9. Liability</h3>
            <p>
                9.1 This Clause 9 sets out the entire financial liability of OUP (including any liability for the acts or omissions of its employees, agents, consultants, and subcontractors) to You including those arising from breach of contract, use made by You of OEVT, the Software and/or the Products, and representation, statement or tortious act or omission (including negligence) arising under or in connection with Your use of OEVT, the Software and/or the Products.
            </p>
            <p>
                9.2 Nothing in these Terms excludes our liability for death or personal injury caused by our negligence; or for fraud or fraudulent misrepresentation.
            </p>

            <p>
                9.3 OUP’s Liability if You are a Business
            </p>
            <div class="pl-1">
                <p>
                    9.3.1 Subject to Clause 9.2 we shall not be liable to You for any:
                </p>
                <p>
                    9.3.1.1 indirect, consequential and/or special loss or damage;
                </p>
                <p>
                    9.3.1.2 loss of profit (direct or indirect);
                </p>
                <p>
                    9.3.1.3 loss of revenue, loss of teaching time or loss of business (in each case whether direct or indirect);
                </p>
                <p>
                    9.3.1.4 loss of goodwill, loss of reputation or loss of opportunity (in each case whether direct or indirect);
                </p>
                <p>
                    9.3.1.5 loss of anticipated saving or loss of margin (in each case whether direct or indirect);
                </p>
                <p>
                    9.3.1.6 wasted management, operational or other time (in each case whether direct or indirect);
                </p>
                <p>
                    9.3.1.7 loss of any data, content and/or material (in each case, whether direct or indirect); and/or
                </p>
                <p>
                    9.3.1.8 liability of any of the other parties to third parties (whether direct or indirect), arising out of or in connection with these Terms and/or in connection with Your use of OEVT, the Software and/or the Products, whether in contract, tort, misrepresentation, under statute or otherwise, howsoever caused including (without limitation) by negligence and also including (without limitation) any liability arising from a breach of, or a failure to perform or defect or delay in performance of, any of our obligations under these Terms.
                </p>
                <p>
                    9.3.2 Subject only to Clause 9.2 but without prejudice to Clause 9.3.1, our total aggregate liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated performance of these Terms shall be limited to the Fees paid You to us during the 12 months preceding the date on which the claim arose or £5000 (whichever higher). You acknowledge that this limitation is reasonable.
                </p>
            </div>
            <p>
                9.4 OUP’s Liability if You are a consumer
            </p>
            <div class="pl-1">
                <p>
                    9.4.1 Subject to clause 9.2, if we fail to comply with these Terms, we are responsible for any loss or damage You suffer that is a foreseeable result of our breach of these Terms or our negligence, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if they were an obvious consequence of our breach or in the case of a consumer Buyer, if they were contemplated by that Buyer and us at the time the Buyer bought the Product(s).
                </p>
                <p>
                    9.4.2 If You are a consumer as opposed to a business, You agree that we only make available OEVT and/or the Products for domestic and private use. You agree not to use the Products for any commercial, business or re-sale purposes, and we have no liability to You for:
                </p>
                <p>
                    9.4.2.1 any loss of profit (whether direct or indirect);
                </p>
                <p>
                    9.4.2.2 any loss of business (whether direct or indirect);
                </p>
                <p>
                    9.4.2.3 any business interruption (whether direct or indirect);
                </p>
                <p>
                    9.4.2.4 any loss arising from viruses, Trojans or other harmful elements being introduced into Your computer or network (whether direct or indirect);
                </p>
                <p>
                    9.4.2.5 any loss of business opportunity (whether direct or indirect); and/or
                </p>
                <p>
                    9.4.2.6 any indirect, consequential or special loss.
                </p>
            </div>
            <p>
                9.5 If You are an Authorised User who is not also the Buyer, You irrevocably agree not to bring or threaten to bring any claims or proceedings directly against OUP. Any claims, proceedings, problems, dissatisfaction or issues which You have should be brought or raised directly with Your Buyer. If any Authorised Users bring or threaten to bring any claims or proceedings against OUP, the Buyer shall indemnify and keep OUP indemnified against any costs, losses, damage and/or expenses incurred by OUP.
            </p>
        </section>

        <section id="term-and-termination">
            <h3>10. Term and termination</h3>
            <p>
                10.1 Unless expressly stated otherwise in the relevant Product Specific Terms, You agree that the licence to access any Product shall expire at the end of the Licence Period.
            </p>
            <p>
                10.2 Without prejudice to any rights which have accrued under these Terms, You or OUP may terminate Your access to OEVT, the Products and or any contracts (“Contracts”) arising between OUP and You which relate to OEVT and/or the Products if the other party:
            </p>
            <div class="pl-1">
                <p>
                    10.2.1 is in material breach of any of its obligation under these Terms and if such breach is remediable, fails to remedy that breach within 30 days of that party being notified in writing of the breach; or
                </p>
                <p>
                    10.2.2 is made bankrupt, is unable to pay its debts within the meaning of section 123 of the Insolvency Act 1986, or if the other party ceases or threatens to cease to trade, or if the other party makes an assignment for the benefit of, or a composition with its creditors or other arrangement of similar import or has a receiver, administrative receiver, administrator or a similar officer appointed over all or a substantial part of its assets, or if a petition is passed or an order is made by a court of competent jurisdiction or resolution is passed for the winding up of the other party (other than for the purpose of a bona fide solvent reconstruction or amalgamation) or any similar circumstances arise in any jurisdiction.
                </p>
            </div>
        </section>

        <section>
            <h3>11. Effect of termination</h3>
            <p>
                11.1 On expiry or termination of any Contracts (as defined in Clause 10.2 above) or Your right to use OEVT and/or the Products for any reason and subject to any express provisions set out elsewhere in these Terms:
            </p>
            <div class="pl-1">
                <p>
                    11.1.1 all outstanding sums payable by the Buyer to OUP shall immediately become due and payable; and
                </p>
                <p>
                    11.1.2 all rights and licences granted to You to use the Software, the Products and OEVT under these Terms shall cease.
                </p>
            </div>

            <p>
                11.2 The accrued rights and liabilities of the parties as at termination and the continuation of any provision expressly stated to survive or implicitly surviving termination shall not be affected.
            </p>
            <p>
                11.3 If your access to a Product ceases (e.g. the Licence Period has expired), You will no longer be able to access that Product and/or Your User Materials that are associated to that Product. OUP reserves the right to delete Your User Materials which relate to that Product after 90 days from the date when You cease to have access to the Product in question.
            </p>
        </section>

        <section id="force-majeure">
            <h3>12. Force majeure</h3>
            <p>
                We will have no liability to You if we are prevented from, or are delayed in performing our obligations due to any circumstances and/or reasons which are outside of our control. These circumstances and reasons include strikes, lock-outs or other industrial disputes (whether involving the workforce of OUP or any other party), failure of a utility service or transport network, act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation or direction, sanctions, accident, breakdown of plant or machinery, fire, flood, storm, default of suppliers or subcontractors and/or non-performance of any services by OUP's suppliers, banks or subcontractors.
            </p>
        </section>

        <section id="maintenance">
            <h3>13. Maintenance</h3>
            <p>
                13.1 If You are unable to access OEVT, You must first ascertain whether the inability to access OEVT is caused by a failure on the part of Your device. If the fault lies with OEVT and not Your device, You must report the issue by emailing OUP at eltsupport@oup.com.
            </p>
            <p>
                13.2 Upon receiving Your report, OUP shall use reasonable endeavours to resolve and rectify the problem to allow You to access OEVT
            </p>
            <p>
                13.3 Where it is subsequently established that the fault does not lie with OEVT but with Your device or Your internet services, we reserve the right to charge You a reasonable administrative fee.
            </p>
        </section>

        <section id="notices">
            <h3>14. Notices</h3>
            <p>
                14.1 Unless these Terms prescribe a specific manner in which notice should be given, all notices to be given under these Terms shall be in writing and shall either be delivered personally or sent by courier and shall be deemed duly served:
            </p>
            <div class="pl-1">
                <p>
                    14.1.1 in the case of a notice delivered personally, at the time the same is left at the
                    address of, or handed to a representative of, the party to be served; and
                </p>
                <p>
                    14.1.2 in the case of courier, two clear business days after the date of dispatch.
                </p>
            </div>

            <p>
                14.2 Notwithstanding Clause 14.1, OUP may notify You of any changes to these Terms or inform You of any changes to OEVT or the Products by emailing you using the email address which You provided to OUP and such notice shall be deemed to have been delivered the following business day or by other appropriate means.
            </p>
        </section>

        <section id="general-provisions">
            <h3>15. General provisions</h3>
            <p>
                15.1 These Terms constitute the whole agreement between OUP and You and supersede all previous agreements between OUP and You relating to Your use of OEVT. Nothing in these Terms shall limit or exclude any liability for fraud.
            </p>
            <p>
                15.2 We may amend these Terms from time to time. If we have to revise these Terms, we will give You at least one month's written notice of any changes to these Terms before they take effect. If You do not wish to accept the changes, the Buyer can choose to cancel the contract in respect of the Products affected by the change to these Terms and we will refund the Buyer any portion of the Fees paid by You (less a reasonable sum in respect of the Your use of the Product purchased by the Buyer).
            </p>
            <p>
                15.3 A waiver of any right under these Terms is only effective if it is in writing and it applies only to the party to whom the waiver is addressed and the circumstances for which it is given. No waiver shall be implied by taking or failing to take any other action.
            </p>
            <p>
                15.4 If any provision (or part of a provision) of these Terms is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force.
            </p>
            <p>
                15.5 If any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part of it were deleted, the provision shall apply with whatever modification is necessary to give effect to the commercial intention of the parties.
            </p>
            <p>
                15.6 To the extent that it is applicable and legally enforceable, in the event of any inconsistencies between the provisions set out in these Terms and those in the terms and conditions governing the purchase of the Products from OUP, the parties agree that the provisions in these Terms will prevail.
            </p>
        </section>

        <section id="governing-law-and-jurisdiction">
            <h3>16. Governing law and jurisdiction</h3>
            <p>
                16.1 These Terms and any disputes or claims arising out of or in connection with its subject matter are governed by and construed in accordance with the law of England.
            </p>
            <p>
                16.2 The parties irrevocably agree that the courts of England have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with these Terms and/or Your use of OEVT, the Products and the Software.
            </p>
            <p>
                16.3 Notwithstanding Clauses 16.1 and 16.2, nothing in these Terms shall limit the right of OUP to take proceedings against You in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdiction preclude the taking of proceedings in any other jurisdiction, whether concurrently or not, to the extent permitted by the law of such other jurisdiction.
            </p>
        </section>
    </div>
    );
}

export default TermsAndConditions;