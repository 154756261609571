import React from 'react';
import i18next from "i18next";
import PromiseComponent from "../PromiseComponent";
import FirebaseDatabaseService from "../services/FirebaseDatabaseService";
import DataHelper from "../helpers/DataHelper";
import Constants from '../Constants';
import { Sentry } from 'react-activity';
import FirestoreDatabaseService from '../services/FirestoreDatabaseService';
import LanguageMap from '../maps/LanguageMap';

class Registration extends PromiseComponent {


    props: {
        mail: string,
        company: Object,
        locales: Object,
        goOnWithCode: () => mixed,
        goToLogin: () => mixed,
        goToSupport?: () => mixed,
        codeType: string,
        userId?: string
    };

    state : {
        mail: string,
        password: string,
        passwordConfirmation: string,
        passwordIsWeak: boolean,
        passwordConfirmedIsWeak: boolean,
        passwordShowClear: boolean,
        name: string,
        languageSelectionOpen: boolean,
        registering: boolean,
        userData: Object
    } = {
       mail: "",
        password: "",
        passwordConfirmation: "",
        passwordIsWeak: true,
        passwordConfirmedIsWeak: null,
        passwordShowClear: false,
        name: "",
        languageSelectionOpen: false,
        registering: false,
        userData: {}
    };

    componentDidMount = async () => {
        if (this.props.codeType === Constants.CODE_TYPE.USER_MIGRATION) {
            const firestoreDatabaseService = new FirestoreDatabaseService();
            const userData = await firestoreDatabaseService.getUserData(this.props.userId);
            if (!userData) {
                this.props.goToSupport();
            } else {
                if (userData.User.Locale && userData.User.Locale !== 'ENGLISH' && LanguageMap[userData.User.Locale]) {
                    await i18next.changeLanguage(LanguageMap[userData.User.Locale]);
                }
                this.setState({ name: userData.User.UserName, userData: userData });
            }
        }
    };

    changeMailText = (event) => {
        let text = event.target.value.replace(/\s/g, "");
        this.setState({mail: text});
    };

    changeNameText = (event) => {
        let text = event.target.value.replace(/\s/g, "");
        this.setState({name: text});
    };

    changePasswordText = (event) => {
        let text = event.target.value.replace(/\s/g, "");
        this.setState({password: text, passwordIsWeak: this.isPasswordWeak(text) !== ""});
    };

    changePasswordConfirmationText = (event) => {
        let text = event.target.value.replace(/\s/g, "");
        this.setState({passwordConfirmation: text});
    };

    checkUserInput = async () => {
        //some plausibility checks
        this.setState({ registering: true })
        const firebaseDatabaseService = new FirebaseDatabaseService();
        let passwordErrors = this.isPasswordWeak(this.state.password);
        this.setState({
            passwordConfirmedIsWeak: passwordErrors !== ""
        });
        if (await firebaseDatabaseService.checkIfAuthMailExists(this.state.mail)) {
            alert(
                i18next.t('WEB__EMAIL_ALREADY_IN_USE')
            )
            this.setState({ registering: false })
        } else if (passwordErrors) {
            alert(
                passwordErrors
            );
            this.setState({ registering: false })
        } else if (!DataHelper.verifyMail(this.state.mail)) {
            alert(
                i18next.t('ALERT__ERROR_INVALID_EMAIL_TITLE')
            );
            this.setState({ registering: false })
        } else if (this.state.password.length < 6) {
            alert(
                i18next.t('ALERT__ERROR_WEAK_PASSWORD_TITLE')
            );
            this.setState({ registering: false })

        } else if (this.state.passwordConfirmation !== this.state.password) {
            alert(
                i18next.t("ALERT__ERROR_PASSWORD_CONFIRMATION_WRONG")
            )
            this.setState({ registering: false })

        } else if (this.state.name.length < 3) {
            alert(
                i18next.t('ALERT_CREATE_PLAYER__NAME_MESSAGE')
            );
            this.setState({ registering: false })

        } else {
            await this.props.goOnWithCode(this.state.name, this.state.mail, DataHelper.encryptPassword(this.state.password), "", this.state.userData);
            this.setState({ registering: false })
        }
    };


    isPasswordWeak(password: string) {
        let errorMessage = "";
        if (password.length < 8) {
            errorMessage += i18next.t('PASSWORD_TOO_SHORT') + "\n";
        }
        if (!Constants.UPPER_CASE_REGEX.test(password)) {
            errorMessage += i18next.t('PASSWORD_NO_UPPER_CASE') + "\n";
        }
        if (!Constants.LOWER_CASE_REGEX.test(password)) {
            errorMessage += i18next.t('PASSWORD_NO_LOWER_CASE') + "\n";
        }
        if (!Constants.NUMERIC_REGEX.test(password) && !Constants.SPECIAL_REGEX.test(password)) {
            errorMessage += i18next.t('PASSWORD_NO_NUMBER_OR_SPECIAL')
        }
        return errorMessage;
    }

    renderTermsAndDataLinks = () => {
        let translation = i18next.t("DESCRIPTION_REGISTRATION__TERMS_AND_CONDITION_NEW");
        let language = Constants.AGBLinks[i18next.language.substr(0,2)] ? i18next.language.substr(0,2) : 'en';
        let urls = [Constants.AGBLinks[language], Constants.PrivacyLinks[language]];
        let splittedText = translation.split('@link@');
        let parts = [];
        for (let i = 0; i < splittedText.length; i++) {
            if (i === 1) {
                parts.push(
                    <a key={"link1"} href={urls[0]} rel="noopener noreferrer" target="_blank">
                        <p>{splittedText[i] + " "}</p>
                    </a>
                );
            } else if (i === 3) {
                parts.push(
                    <a key={"link2"} href={urls[1]} rel="noopener noreferrer" target="_blank">
                        <p>{splittedText[i] + " "}</p>
                    </a>
                );
            } else if (i === 5) {
                parts.push(
                    <a key={"link3"} href={urls[2]} rel="noopener noreferrer" target="_blank">
                        <p>{splittedText[i] + " "}</p>
                    </a>
                );
            } else {
                let splittedPart = splittedText[i].trim().split(' ');
                for (let j = 0; j < splittedPart.length; j++) {
                    parts.push(
                        <p key={"text" + i + j}>{splittedPart[j] + " "}</p>
                    );
                }
            }
        }

        return parts;
    };

    renderLanguageInput = () => {
        return (
            <div className="language-container"
                 onClick={async () => {
                     await this.promisedSetState({languageSelectionOpen: true});
                 }}>
                <img className="language-flag"
                     alt={'flag-' + i18next.language.substr(0, 2)}
                     src={this.props.locales[i18next.language.substr(0, 2)].imageUrl}/>
                <p className="language-label">
                    {this.props.locales[i18next.language.substr(0, 2)].label}
                </p>
                <p className="language-selection-icon">
                    >
                </p>
            </div>
        )
    };

    renderLanguageSelection = () => {
        if (this.state.languageSelectionOpen) {
            let options = [];
            for (let key in this.props.locales) {
                if (this.props.locales.hasOwnProperty(key)) {
                    options.push(
                        <div className="language-container-list-item"
                             key={key} onClick={async () => {
                            await i18next.changeLanguage(key);
                            await this.promisedSetState({languageSelectionOpen: false});
                        }}>
                            <img className="language-flag"
                                 alt={'flag-' + key}
                                 src={this.props.locales[key].imageUrl}/>
                            <p className="language-label">
                                {this.props.locales[key].label}
                            </p>
                        </div>
                    );
                }
            }

            return (
                <div className="language-container-list">
                    {
                        options
                    }
                </div>
            );
        }
    };

    renderGoToLogin = () => {
        if (this.props.codeType === Constants.CODE_TYPE.COURSE_CODE) {
            return(
                <button className="go-to-login-text" onClick={this.props.goToLogin}>
                    <p>
                        {i18next.t("BUTTON_TEXT__ALREADY_HAVE_ACCOUNT")}
                    </p>
                </button>
            )
        }
    };

    renderRegisterButtonText = () => {
        if (this.state.registering) {
            return (
                <Sentry size={12}/>
            );
        } else {
            return (
                <p>
                    {i18next.t("BUTTON__ACCEPT_AND_START")}
                </p>
            )
        }
    }

    renderPasswordText = () => {
        if (this.state.passwordConfirmedIsWeak) {
            return (
                <p className="password-requirements-failed">
                    {
                        i18next.t('PASSWORD_REQUIREMENTS_FAILED')
                    }
                </p>
            )
        } else {
            return (
                <p className="password-requirements-info">
                    {
                        i18next.t('PASSWORD_REQUIREMENTS_INFO')
                    }
                </p>
            )
        }
    }

    renderPasswordField = () => {
        return (
            <div className="password-container">
                <img className="password-icon-eye"
                     src={this.state.passwordShowClear ? require('../assets/img/icon_eye_closed.png') : require('../assets/img/icon_eye_open.png')}
                     onClick={() => {
                         this.setState({passwordShowClear: !this.state.passwordShowClear})
                     }} alt={''}/>
                <input
                    style={{ width: 'calc(100% - 32px)', maxWidth: '332px' }}
                    type={this.state.passwordShowClear ? "text" : "password"}
                    onChange={this.changePasswordText}
                    value={this.state.password}
                    placeholder={i18next.t("FORM_PLACEHOLDER__PASSWORD")}
                />
                <img className="password-requirements-icon"
                     src={this.state.passwordIsWeak ? require('../assets/img/red_cross.png') : require('../assets/img/green_check_mark.png')}
                     onClick={() => {
                         this.setState({passwordShowClear: !this.state.passwordShowClear})
                     }} alt={''}/>
            </div>
        )
    }

    render () {
        return (
            <div className="content-container">
                <p>
                    {
                        i18next.t('WEB_INFO__PLEASE_REGISTER')
                    }
                </p>
                <input
                    style={{  width: 'calc(100% - 32px)', maxWidth: '368px' }}
                    type="email"
                    onChange={this.changeMailText}
                    value={this.state.mail}
                    placeholder={i18next.t("FORM_PLACEHOLDER__EMAIL_ADRESS")}
                />
                <input
                    style={{ width: 'calc(100% - 32px)', maxWidth: '368px' }}
                    type="name"
                    onChange={this.changeNameText}
                    value={this.state.name}
                    placeholder={i18next.t("FORM_PLACEHOLDER__PLAYER_NAME")}
                />
                {
                    this.renderPasswordField()
                }
                {
                    this.renderPasswordText()
                }
                <input
                    style={{ width: 'calc(100% - 32px)', maxWidth: '368px' }}
                    type={this.state.passwordShowClear ? "text" : "password"}
                    onChange={this.changePasswordConfirmationText}
                    value={this.state.passwordConfirmation}
                    placeholder={i18next.t("FORM_PLACEHOLDER__PASSWORD_CONFIRMATION")}
                />
                {
                    this.renderLanguageInput()
                }
                {
                    this.renderGoToLogin()
                }
                <div className="terms-outer-container">
                    <div className="terms-container">
                        {this.renderTermsAndDataLinks()}
                    </div>
                </div>
                <button
                    disabled={this.state.registering}
                    onClick={this.checkUserInput}>
                    {this.renderRegisterButtonText()}
                </button>
                {
                    this.renderLanguageSelection()
                }
            </div>
        );
    }

}

export default Registration;
