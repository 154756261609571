
import FirebaseConnector from "./FirebaseConnector";
import UserModel from "../models/UserModel";
import UserLanguageModel from "../models/UserLanguageModel";
import SettingsModel from "../models/SettingsModel";
import StatisticsModel from "../models/StatisticsModel";

class FirebaseDatabaseService {

    firebaseConnector = new FirebaseConnector();

    checkIfAuthMailExists = async (mail: string): Promise<boolean> => {
        return this.firebaseConnector.checkIfAuthMailExists(mail);
    };

    registerWithMailAuth = (mail: string, password: string): Promise<any> => {
        return this.firebaseConnector.registerWithMailAuth(mail, password);
    };

    signInWithMail = (mail: string, password: string): Promise<any> => {
        return this.firebaseConnector.signInWithMail(mail, password);
    };

    isCourseCodeValidAndUnused = (code: string) => {
        return this.firebaseConnector.isCourseCodeValidAndUnused(code);
    };

    isCompanyCodeValidAndUnused = (companyId: number, code: string) => {
        return this.firebaseConnector.isCompanyCodeValidAndUnused(companyId, code);
    };

    getDownloadUrl = (path) => {
        return this.firebaseConnector.getDownloadUrl(path);
    };

    locales = () => {
        return this.firebaseConnector.locales();
    };

    communities = () => {
        return this.firebaseConnector.communities();
    };

    markCodeAsUsed = (code: string, uid: string) => {
      return this.firebaseConnector.markCodeAsUsed(code, uid);
    };

    markCompanyCodeAsUsed = (sponsorId: number, code: string, uid: string) => {
        return this.firebaseConnector.markCompanyCodeAsUsed(sponsorId, code, uid);
    };

    writeRegistration = (firebaseId: string, companyId: number, code: string, validated: boolean, langCode: string) => {
        return this.firebaseConnector.writeRegistration(firebaseId, companyId, code, validated, langCode);
    };

    addBranchLink = (uid: string, branchLink: string) => {
        return this.firebaseConnector.addBranchLink(uid, branchLink);
    }

    saveUserInitial = async (user: UserModel, sponsor: Object, acceptedTerms: ?number): Promise<void> => {

        const userDataToStore = UserModel.serializeWithoutId(user);
        //we should add the course from the code here; only possible after redeeming the code...
        const userLanguages: Array<UserLanguageModel> = [];
        //do we need topics?
        //const userLanguagesTopics: { [language: string]: { [title: string]: { [topic: string]: boolean } } } = await localDatabaseService.getAllCourseTopics(user);
        //save some kind of default settings?
        const settings: SettingsModel = SettingsModel.createFromParams(true, false, true, true, true, '#ffffff', false, false, false);

        //do we need this?
        const statistics: StatisticsModel = StatisticsModel.createFromParams(0,0,0,0,0,0);
        //add sponsor from code here
        const sponsors: Array<Object> = [];
        sponsors.push(sponsor);

        // do not save auth or personal data
        userDataToStore.Mail = null;
        userDataToStore.Password = null;
        userDataToStore.OnboardingCompleted = true;

        const notificationTokens = [];

        const userToStore = {
            user: userDataToStore,
            userLanguages: UserLanguageModel.serializeMultiple(userLanguages),
            userLanguagesTopics: {},
            settings: SettingsModel.serialize(settings),
            wordsProgress: {},
            sessions: {},
            sponsor: sponsors,
            statistics: StatisticsModel.serialize(statistics),
            notificationTokens: notificationTokens
        };

        await this.firebaseConnector.saveUserInitial(userToStore, acceptedTerms);
    };

    getCourses = async () => {
        return await this.firebaseConnector.getCourses();
    };

    saveUserLanguages = async (userId: string, userLanguages:Array<Object>): Promise<void> => {
        await this.firebaseConnector.saveUserLanguages(userId, UserLanguageModel.serializeWithoutIdMultiple(userLanguages));
    };

    saveWordsProgress = async (userId: string, wordsProgress: Object): Promise<void> => {
        await this.firebaseConnector.saveWordsProgress(userId, wordsProgress)
    }

    deleteDeviceId = async (userId: string): Promise<void> => {
        await this.firebaseConnector.deleteDeviceId(userId);
    };

    setUpdateBusinessUserLanguages = async (userId: string): Promise<void> => {
        await this.firebaseConnector.setUpdateBusinessUserLanguages(userId);
    };

    getUserLanguages = async (userId: string): Promise<Array<Object>> => {
        return await this.firebaseConnector.getUserLanguages(userId);
    };

    getUserSponsors = async (userId: string): Promise<Array<Object>> => {
        return await this.firebaseConnector.getUserSponsors(userId);
    };

    setNewSponsor = async (userId: string, sponsors: Array<Object>): Promise<void> => {
        return await this.firebaseConnector.setNewSponsor(userId, sponsors);
    };

    getCourseTranslation = async (courseName: string): Promise<Object> => {
        return await this.firebaseConnector.getCourseTranslation(courseName);
    }

    getAvatar = async (sponsorId: string): Promise<string> => {
        return await this.firebaseConnector.getAvatar(sponsorId);
    };

    getLanguageTests = async (): Promise<Object> => {
        return await this.firebaseConnector.getLanguageTests();
    };
}

export default FirebaseDatabaseService;
