import firebase from '../firebase';

class FirebaseConnector {

    communities = async () => {
        return (await firebase.database().ref('Communities').orderByKey().once('value')).val();
    };

    locales = async () => {
        let locales = (await firebase.database().ref('Locales').once('value')).val();
        delete locales.version;

        for(let key in locales){
            if(locales.hasOwnProperty(key)){
                locales[key].imageUrl = this.getDownloadUrl('Images/Locales/' + locales[key].image);
            }
        }

        for(let key in locales){
            if(locales.hasOwnProperty(key)){
                locales[key].imageUrl = await locales[key].imageUrl;
            }
        }

        return locales;
    };

    getCourses = async () => {
        const coursesSnapshot = await firebase.firestore().collection('Courses').get();
        const coursesData = {};
        coursesSnapshot.forEach((snapshot) => {
            coursesData[snapshot.data().language + snapshot.data().title] = snapshot.data();
        });
        return coursesData;
    };

    isCourseCodeValidAndUnused = async (code) => {
        let returnValue = { exists: false, valid: false, expired: false, language: '', title: '', communityId: '', validForMonths: null };
        let companyCode = (await firebase.database().ref('CourseCodes/'  + code).once('value')).val();
        if (companyCode != null) {
            returnValue.exists = true;
            if(companyCode.used === false || companyCode.count > 0){
                returnValue.valid = true;
            }
            if(companyCode.validUntil < new Date().getTime()){
                returnValue.expired = true;
            }
            if (companyCode.validForMonths) {
                returnValue.validForMonths = companyCode.validForMonths;
            }
            if(companyCode.language) {
                returnValue.language = companyCode.language;
            }
            if (companyCode.title) {
                returnValue.title = companyCode.title;
            }
            if (companyCode.community) {
                returnValue.communityId = companyCode.community;
            }
        }
        return returnValue;
    };

    isCompanyCodeValidAndUnused = async (companyId, code) => {
        let returnValue = { exists: false, valid: false, expired: false };
        let companyCode = (await firebase.database().ref('companies/' + companyId + '/' + code).once('value')).val();
        if (companyCode != null) {
            returnValue.exists = true;
            if(companyCode.used === false || companyCode.count > 0){
                returnValue.valid = true;
            }
            if(companyCode.expirationDate < new Date().getTime()){
                returnValue.expired = true;
            }
        }
        return returnValue;
    };

    markCodeAsUsed = async (code, uID) => {
        let returnValue = {success: false, validUntil: ""};
        try {
            await firebase.database().ref('CourseCodes/' + code).transaction((code) => {
                if (code !== null) {
                    // check for single use codes
                    if (code.used !== null && code.used === false && !code.uID && (!code.validUntil || code.validUntil > new Date().getTime())) {
                        code.used = true;
                        code.uID = uID;
                        code.timestamp = firebase.database.ServerValue.TIMESTAMP;
                        returnValue.success = true;
                    }
                    // check for multi use codes
                    else if (code.count && code.count > 0 && (!code.validUntil || code.validUntil > new Date().getTime())) {
                        code.count = code.count - 1;
                        if (!code.uIDs) {
                            code.uIDs = [];
                        }
                        code.uIDs.push({
                            uID: uID,
                            timestamp: firebase.database.ServerValue.TIMESTAMP
                        });
                        returnValue.success = true;
                    }
                    // check for expirationDate if marking was successful
                    if (returnValue.success && code.validUntil) {
                        returnValue.validUntil = code.validUntil;
                    }
                }
                return code;
            });
        } catch (e) {
            return returnValue;
        }
        return returnValue;
    };

    markCompanyCodeAsUsed = async (companyId, code, uID) => {
        let returnValue = {success: false, expirationDate: ""};
        try {
            await firebase.database().ref('companies/' + companyId + '/' + code).transaction((code) => {
                if (code !== null) {
                    // check for single use codes
                    if (code.used !== null && code.used === false && !code.uID && (!code.expirationDate || code.expirationDate > new Date().getTime())) {
                        code.used = true;
                        code.uID = uID;
                        code.timestamp = firebase.database.ServerValue.TIMESTAMP;
                        returnValue.success = true;
                    }
                    // check for multi use codes
                    else if (code.count && code.count > 0 && (!code.expirationDate || code.expirationDate > new Date().getTime())) {
                        code.count = code.count - 1;
                        if (!code.uIDs) {
                            code.uIDs = [];
                        }
                        code.uIDs.push({
                            uID: uID,
                            timestamp: firebase.database.ServerValue.TIMESTAMP
                        });
                        returnValue.success = true;
                    }
                    // check for exirationDate if marking was successful
                    if (returnValue.success && code.expirationDate) {
                        returnValue.expirationDate = code.expirationDate;
                    }
                }
                return code;
            });
        } catch (e) {
            return returnValue;
        }
        return returnValue;
    };

    writeRegistration = async (firebaseId, companyId, code, validated, langCode) => {
        if(firebaseId && companyId && code && langCode){
            await firebase.database().ref('registrations/' + firebaseId).set({
                companyId: companyId,
                voucherCode: code,
                validated: validated ? new Date().getTime() : 0,
                languageCode: langCode
            });
        } else {
            throw new Error("input missing");
        }
    };

    addBranchLink = async (firebaseId, branchLink) => {
        if(firebaseId && branchLink){
            await firebase.database().ref('registrations/' + firebaseId + "/branchLink").set(branchLink);
        } else {
            throw new Error("input missing");
        }
    };

    getDownloadUrl = (path) => {
        return firebase.storage().ref(path).getDownloadURL();
    };

    checkIfAuthMailExists = async (mail: string): Promise<boolean> => {
        try {
            const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(mail);
            return signInMethods && signInMethods.length > 0;
        }  catch (e) {
            return false;
        }
    };

    registerWithMailAuth = async (mail: string, password: string): Promise<any> => {
        try {
            const auth = await firebase.auth().createUserWithEmailAndPassword(mail, password);
            return auth.user.toJSON();
        } catch (e) {
            console.log(e);
            if (e.code === "auth/email-already-in-use") {
                return e.code;
            }
            return this.registerWithMailAuth(mail, password);
        }
    };

    signInWithMail = async (mail, password) => {
        let auth = await firebase.auth().signInWithEmailAndPassword(mail, password);
        return auth.user.toJSON();
    };

    saveUserInitial = async (userToStore: Object, acceptedTerms: ?number, retries: number = 0): Promise<any> => {
        try {
            userToStore.user.AcceptedTerms = acceptedTerms || firebase.database.ServerValue.TIMESTAMP;
            return await firebase.database().ref('users/' + userToStore.user.FirebaseId).set(userToStore);
        } catch (e) {
            console.log(e);
            const retry = retries++;
            if (retry < 3) {
                return this.saveUserInitial(userToStore, acceptedTerms, retry);
            }
        }
    };

    saveUserLanguages = async (userId: string, userLanguages: Array<Object>, retryDone: boolean = false): Promise<any> => {
        try {
            return await firebase.database().ref('users/' + userId + '/userLanguages').set(userLanguages);
        } catch (e) {
            console.log(e);
            if (!retryDone) {
                return this.saveUserLanguages(userId, userLanguages, true);
            }
        }
    };

    saveWordsProgress = async (userId: string, wordsProgress: Object): Promise<any> => {
      try {
          return await firebase.database().ref('users/' + userId + '/wordsProgress').set(wordsProgress);
      }  catch (e) {
          return this.saveWordsProgress(userId, wordsProgress);
      }
    };

    deleteDeviceId = async (userId: string): Promise<void> => {
        try {
            return await firebase.database().ref('users/' + userId +'/user/Device').set('0');
        } catch (e) {
            return this.deleteDeviceId(userId);
        }
    };

    setUpdateBusinessUserLanguages = async (userId: string): Promise<void> => {
        try {
            return await firebase.database().ref('users/' + userId +'/user/UpdateBusinessUserLanguages').set(true);
        } catch (e) {
            return this.setUpdateBusinessUserLanguages(userId);
        }
    };

    getUserLanguages = async (userId: string): Promise<Array<Object>> => {
        try {
            return  (await firebase.database().ref('users/' + userId + '/userLanguages').once('value')).val();
        } catch (e) {
            console.log(e);
            return this.getUserLanguages(userId);
        }
    };

    getUserSponsors = async (firebaseID: string): Promise<Array<Object>> => {
        try {
            return (await firebase.database().ref('users/' + firebaseID + '/sponsor').once('value')).val();
        } catch (e) {
            return this.getUserSponsors(firebaseID);
        }
    };

    setNewSponsor = async (firebaseID: string, sponsors: Array<Object>): Promise<void> => {
      try {
          return await firebase.database().ref('users/' + firebaseID + '/sponsor').set(sponsors);
      }  catch (e) {
          return this.setNewSponsor(firebaseID);
      }
    };

    getCourseTranslation = async (courseName: string): Promise<Object> => {
        try {
            return (await firebase.database().ref('Translations/Courses/DisplayNames/' + courseName).once('value')).val();
        } catch (e) {
            return this.getCourseTranslation(courseName);
        }
    };

    getAvatar = async (sponsorId: string): Promise<string> => {
        try {
            return (await firebase.database().ref('SponsorTrikots/' + sponsorId + '/w/0').once('value')).val();
        } catch (e) {
            return this.getAvatar(sponsorId);
        }
    };

    getLanguageTests = async (): Promise<Object> => {
        try {
            return (await firebase.database().ref('languageTests').once('value')).val();
        } catch (e) {
            return this.getLanguageTests();
        }
    }
}

export default FirebaseConnector;
