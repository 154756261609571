import React, { Component } from 'react';
import i18next from 'i18next';

class ContactSupport extends Component {
    setEmailLink = (text: string) => {
        const textArray = text.split(' ');
        let returnString1 = '';
        let returnString2 = ' ';
        let emailIndex = -1;
        for (let i = 0; i < textArray.length; i++) {
            if (textArray[i] === 'eltsupport@oup.com') {
                emailIndex = i;
            } else if (emailIndex === -1) {
                returnString1 += textArray[i];
                returnString1 += ' ';
            } else if (i > emailIndex) {
                returnString2 += textArray[i];
                returnString2 += ' ';
            }
        }

        return (
                <p>{returnString1}
                <a href="mailto:eltsupport@oup.com?subject=OEVT migration">eltsupport@oup.com</a>
                {returnString2}</p>);

    };

    render () {
        return (
            <div className="content-container">
                <div className={'info-text-onboarding'}>
                    <h2><b>{i18next.t('OUP_TITLE__CONTACT_SUPPORT')}</b></h2>
                    <p>{i18next.t('OUP_INFO__CONTACT_SUPPORT_1')}</p>
                    {this.setEmailLink(i18next.t('OUP_INFO__CONTACT_SUPPORT_2'))}
                </div>
            </div>
        )
    }
}

export default ContactSupport;
